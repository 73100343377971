import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./PlayTournamentPage.css";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from 'connected-react-router';
import {
  watchTournamentGameStatus,
  startChannel,
} from "../../modules/online/online.actions";
import { joinGame } from "../../modules/online/online.saga";
import * as configLayout from "../../config/layout";
import Board from "../../components/game/Board";
import Header from "../../components/game/Header";
import StandingsTable from "../../components/tournament/StandingsTable";
import RateList from "../../components/tournament/RateList";


const wait = (ms) => new Promise((res) => setTimeout(res, ms));

function PlayTournamentPage(props) {
  const {
    watchTournamentGameStatus,
    goHome,
    startChannelAction,
    serverStatus,
    username,
    tournament,
  } = props;

  const params = useParams();
  const tournamentId = params.tournamentId;

  const [currentGameId, setCurrentGameId] = useState(undefined);

  useEffect(() => {
    startChannelAction();
  }, []);

  const join = useCallback(async (gameId) => {
    await wait(2000);
    setCurrentGameId(undefined);
    const joinPromise = joinGame(gameId, username);
    watchTournamentGameStatus(gameId);
    const data = await joinPromise;
    console.log("joinGame", data);
    setCurrentGameId(gameId);
    watchTournamentGameStatus(gameId);
  }, []);

  useEffect(() => {
    if (serverStatus !== "on") {
      return;
    }

    if (tournament.scheduledGameId) {
      join(tournament.scheduledGameId);
    } else {
      console.log("start game");
    }
  }, [tournament.scheduledGameId, serverStatus]);


  useEffect(() => {
    if (serverStatus !== "on") {
      return;
    }

    if (tournament.activeGameId) {
      setCurrentGameId(tournament.activeGameId);
    } else {
      console.log("set active game");
    }
  }, [tournament.activeGameId, serverStatus]);

  useEffect(() => {
    if (!tournament) {
      return;
    }

    if(tournament.tournamentId !== tournamentId){
      goHome();
    }
  }, [tournament, tournamentId]);


  return (
    <div className="Home">
      <div className="tournament-wrapper">
        <div className="tournament-game">
          {currentGameId ? (
            <>
              <h3>#Game [{currentGameId}]</h3>
              <div style={{ display: "inline-block", marginBottom: "25px" }}>
                <Header />
                <Board />
              </div>
            </>
          ) : (
            <>
              <h3>#waiting for game...</h3>
            </>
          )}
        </div>
        <div className="tournament-stats">
          <StandingsTable />
          <RateList />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startChannelAction: () => dispatch(startChannel()),
  goHome: () => dispatch(push('/')),
  watchTournamentGameStatus: (gameId) =>
    dispatch(watchTournamentGameStatus(gameId)),
});

const mapToProps = (state) => ({
  serverStatus: state.socket.serverStatus,
  username: state.app.username,
  tournament: state.tournament,
});

export default connect(mapToProps, mapDispatchToProps)(PlayTournamentPage);
